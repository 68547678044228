.pagination{
  padding: 0;
  margin:0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  .page-item{
    margin: 0 .1rem;
    &:first-child, &:last-child{
      display: none;
    }
  }
  
}
.page-link{
  border: 1px solid $gray-80;
  display: inline-block;
  padding: 0;
  line-height: 2;
  width: 34px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  color: $blue-primary;
  &:hover{
    color: white;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  .disabled &{
    color: $gray-80;  
    pointer-events: none;
  }
  .active &{
    background-color: $blue-primary;
    color: white;
    border-color: $blue-primary;
    pointer-events: none;
  }
  &[aria-label="Previous"],
  &[aria-label="Next"]{
    overflow: hidden;
    span{
      text-indent: -100px;
      display: inline-block;
      position: relative;
      &:before{
        content: '›';
        position: absolute;
        text-indent: 0;
        left: 0;
        margin-left: -4px;
        font-size: 1.7rem;
        bottom: 10px;
        line-height: .5;
      }
    }
  }
  &[aria-label="Previous"]{
    span:before{
      content: '‹'
    }
  }
}