html {
  font-size: 16px;
  line-height: 1.45;
  font-family: $font-base;
  color: $gray-text;
}

// VISUAL TYPE: 
// base size: 16px
// scale: Augmented fourth - 1.414

// ====================
// HEADINGS
// ====================

h1,
h2,
h3,
h4 {
  margin: 0 0 0.5em;
  font-weight: inherit;
  line-height: 1.2;
  font-family: $font-headings;
}

.h1,
.h2,
.h3,
.h4 {
  display: inline;
}

h1,
.h1 {
  margin-top: 0;
  @media(min-width: $grid-breakpoint){
    font-size: 3.998em;
  }
  font-size: 2.998em;
}

h2,
.h2 {
  @media(min-width: $grid-breakpoint){
    font-size: 2.827em;
  }
  font-size: 2.127em;
  font-weight: 300;
}

h3,
.h3 {
  font-size: 1.569em;
    @media(min-width: $grid-breakpoint){
      font-size: 1.999em;
    }
  }
  
h4,
.h4 {
  font-size: 1.214em;
  @media(min-width: $grid-breakpoint){
    font-size: 1.414em;
  }
}

.lead{
  font-size: 1.2em;
  font-weight: 300;
}

p {
  margin: 0 0 1.3em;
}

strong{
  font-weight: 700;
}

small,
.small {
  font-size: 0.707em;
}

.text-small{
  font-size: 0.875em; // 14px;
  line-height: 1.4;
}

blockquote{
  padding: 0;
  margin: 0 0 $size-4;
  font-style: italic;
  position: relative;
  color: $gray-40;
  p{
    margin: 0;
  }
  &:before{
    content: '“';
    font-size: 4em;
    position: absolute;
    left: -30px;
    top: -20px;
    color: $blue-20;
    opacity: .5;
  }
  small{
    color: $gray-50;
    display: block;
    margin-top: $size-1;
    font-style: normal;
    &::before{
      content: "\2014 \00A0";
    }
  }
}

.list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
}


.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.text-uppercase{
  text-transform: uppercase;
}
.text-lowercase{
  text-transform: lowercase;
}
.text-capitalize{
  text-transform: capitalize;
}

.text-truncate{
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;  
}
.text-nowrap{
  white-space: nowrap;
}

hr{
  margin: $size-2 0;
  border-top: 1px solid $gray-90;
  border-bottom: 0;
}