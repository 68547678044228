$gray-95:                 #F2F2F2;// 1.12:1
$gray-90:                 #E5E5E5;// 1.26:1
$gray-80:                 #CCCCCB;// 1.61:1
$gray-70:                 #B3B3B3;// 2.10:1
$gray-60:                 #999999;// 2.85:1
$gray-50:                 #808080;// 3.95:1
$gray-40:                 #666666;// 5.74:1 AA
$gray-30:                 #4D4D4D;// 8.45:1 AA
$almost-black:            #333333; // 12.63:1 AA
$black:                   #000; // 21.00:1 AA

$blue-98:               #F6FAFE; //1.05:1
$blue-89:               #CDE6F9; // 1.29:1
$blue-77:               #96CAF3; // 1.74:1
$blue-65:               #5FAEEC; // 2.40:1
$blue-50:               #1C8CE3; // 3.55:1 
$blue-primary:          #1776BF; // 4.79:1 AA
$blue-35:               #14629F; // 6.41:1 AA
$blue-27:               #0F4C7B; // 8.97:1 AA
$blue-20:               #0B385B; // 12.13:1 AA
$blue-14:               #08293F; // 15.01:1 AA

$purple-98:           #F9F5FF; // 1.08:1
$purple-92:           #E8D6FF; // 1.36:1
$purple-84:           #D1ADFF; // 1.89:1
$purple-75:           #B780FF; // 2.78:1
$purple-65:           #9A4DFF; // 4.30:1
$purple-56:           #801FFF; // 5.90:1 AA
$purple-47:           #6800F0; // 7.38:1 AA
$purple-34:           #4B00AD; // 11.04:1 AA
$purple-22:           #310070; // 15.39:1 AA
$purple-16:           #230052; // 17.60:1 AA

$pink-98:           #FFF5FB; // 1.07:1
$pink-94:           #FFE0F1; // 1.22:1
$pink-87:           #FFBDE2; // 1.54:1 
$pink-78:           #FF8FCE; // 2.08:1
$pink-69:           #FF61BA; // 2.74:1
$pink-56:           #FF1F9E; // 3.53:1
$pink-accent:       #E0007F; // 4.69:1 AA
$pink-35:           #B30065; // 6.76:1 AA
$pink-24:           #7A0045; // 10.97:1 AA
$pink-17:           #570030; // 14.50:1 AA


$red-97:            #FFF0F0; // 1.11:1
$red-90:            #FFCCCF; // 1.42:1
$red-78:            #FF8F96; // 2.18:1 
$red-68:            #FF5C66; // 3.01:1
$red-56:            #FF1F2D; // 3.83:1
$red-44:            #E0000E; // 5.03:1 AA
$red-37:            #BD000C; // 6.63:1 AA
$red-28:            #8F0009; // 9.67:1 AA
$red-21:            #6B0006; // 12.90:1 AA
$red-16:            #520005; // 15.43:1 AA


$orange-97:         #FFF6F0; // 1.07:1
$orange-92:         #FFE8D6; // 1.18:1
$orange-81:         #FFC89E; // 1.50:1
$orange-67:         #FFA057; // 2.02:1
$orange-56:         #FF801F; // 2.51:1
$orange-45:         #E66400; // 3.39:1
$orange-38:         #C25400; // 4.60:1 AA
$orange-31:         #9E4400; // 6.40:1 AA
$orange-23:         #753200; // 9.49:1 AA
$orange-18:         #5C2800; // 11.94:1 AA


$yellow-95:          #FFFEE6; // 1.02:1 
$yellow-89:          #FFFBC7; // 1.06:1
$yellow-79:          #FFF794; // 1.11:1
$yellow-56:          #FFF01F; // 1.18:1
$yellow-47:          #F0DF00; // 1.38:1
$yellow-42:          #D6C800; // 1.73:1
$yellow-34:          #ADA200; // 2.64:1
$yellow-25:          #807700; // 4.61:1 AA
$yellow-18:          #5C5500; // 7.62:1 AA
$yellow-14:          #474200; // 10.26:1 AA

$green-95:           #FAFEE7; // 1.03:1
$green-89:           #F5FCCA; // 1.07:1
$green-77:           #EAFA8F; // 1.13:1
$green-49:           #CDEF0B; // 1.31:1
$green-44:           #BAD80A; // 1.63:1
$green-38:           #A0B909; // 2.22:1
$green-31:           #829707; // 3.29:1
$green-24:           #657506; // 5.12 AA
$green-18:           #4C5705; // 7.86:1 AA
$green-14:           #3B4503; // 10.30:1 AA


$teal-97:           #F1FDFC; // 1.04:1
$teal-92:           #DBFAF9; // 1.10:1
$teal-81:           #A9F4F0; // 1.24:1
$teal-66:           #64EDE3; // 1.42:1
$teal-46:           #19D2C7; // 1.89:1
$teal-39:           #15B0A6; // 2.69:1
$teal-31:           #108E86; // 4.01:1
$teal-23:           #0C6A64; // 6.44:1 AA
$teal-16:           #094845; // 10.37:1 AA
$teal-12:           #063733; // 13.11:1 AA

$delite-colors:(
white:            white,
gray-95:          $gray-95,         
gray-90:          $gray-90,         
gray-80:          $gray-80,         
gray-70:          $gray-70,         
gray-60:          $gray-60,         
gray-50:          $gray-50,         
gray-40:          $gray-40,         
gray-30:          $gray-30,         
almost-black:     $almost-black,    
black:            $black,           
blue-98:          $blue-98,         
blue-89:          $blue-89,         
blue-77:          $blue-77,         
blue-65:          $blue-65,         
blue-50:          $blue-50,         
blue-primary:     $blue-primary,    
primary:          $blue-primary,    
blue-35:          $blue-35,         
blue-27:          $blue-27,         
blue-20:          $blue-20,         
blue-14:          $blue-14,         
purple-98:        $purple-98,       
purple-92:        $purple-92,       
purple-84:        $purple-84,       
purple-75:        $purple-75,       
purple-65:        $purple-65,       
purple-56:        $purple-56,       
purple-47:        $purple-47,       
purple-34:        $purple-34,       
purple-22:        $purple-22,       
purple-16:        $purple-16,       
pink-98:          $pink-98,         
pink-94:          $pink-94,         
pink-87:          $pink-87,         
pink-78:          $pink-78,         
pink-69:          $pink-69,         
pink-56:          $pink-56,         
pink-accent:      $pink-accent,     
pink-35:          $pink-35,         
pink-24:          $pink-24,         
pink-17:          $pink-17,         
red-97:           $red-97,          
red-90:           $red-90,          
red-78:           $red-78,          
red-68:           $red-68,          
red-56:           $red-56,          
red-44:           $red-44,          
red-37:           $red-37,          
red-28:           $red-28,          
red-21:           $red-21,          
red-16:           $red-16,          
orange-97:        $orange-97,       
orange-92:        $orange-92,       
orange-81:        $orange-81,       
orange-67:        $orange-67,       
orange-56:        $orange-56,       
orange-45:        $orange-45,       
orange-38:        $orange-38,       
orange-31:        $orange-31,       
orange-23:        $orange-23,       
orange-18:        $orange-18,       
yellow-95:        $yellow-95,       
yellow-89:        $yellow-89,       
yellow-79:        $yellow-79,       
yellow-56:        $yellow-56,       
yellow-47:        $yellow-47,       
yellow-42:        $yellow-42,       
yellow-34:        $yellow-34,       
yellow-25:        $yellow-25,       
yellow-18:        $yellow-18,       
yellow-14:        $yellow-14,       
green-95:         $green-95,        
green-89:         $green-89,        
green-77:         $green-77,        
green-49:         $green-49,        
green-44:         $green-44,        
green-38:         $green-38,        
success:          $green-38,        
green-31:         $green-31,        
green-24:         $green-24,        
green-18:         $green-18,        
green-14:         $green-14,        
teal-97:          $teal-97,         
teal-92:          $teal-92,         
teal-81:          $teal-81,         
teal-66:          $teal-66,         
teal-46:          $teal-46,         
teal-39:          $teal-39,         
teal-31:          $teal-31,         
teal-23:          $teal-23,         
teal-16:          $teal-16,         
teal-12:          $teal-12         
);