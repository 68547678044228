.product-intro{
  padding-top: $size-9 + $size-9;
  background-color: $blue-20;
  background-image: radial-gradient(circle at 27%  bottom, $blue-50 0%, $blue-20 60%);
  //background-blend-mode: screen; #, 
  position: relative;
  color: white;
  img{
    width: auto;
    height: auto;
    max-height: 160px;
    max-width: 100%;
  }

  .illustration-wrapper >img{
    max-height: none;
    display: block;
    margin: 0 auto;
  }

  .svg-wrap.bottom{
    margin-top: 180px;
    
    @media(max-width: $grid-breakpoint){
      height: 84px!important;
    }
  }

}
