body {
  // padding-top: 64px; // #main-nav height;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  min-height: calc(100vh - 16px);
  position: relative;
  overflow-x: hidden;
}

a{
  color: $blue-primary;
  text-decoration: none;
  &:hover{
    color: darken($blue-primary, 10);
    text-decoration: underline;
  }
}

.list-collapse-button{
  text-decoration: none;
  position: relative;
  min-width: 90px;
  display: inline-block;

  &:after{
    content: "⌃";
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-left: 8px;
    display: inline-block;
    height: 20px;
    position: absolute;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
  }
  &:before{
    content: "Show";
  }
  &[aria-expanded="true"]{
    &:before{
      content: "Hide"
    }
    &:after{
      -webkit-transform: rotate(0);
              transform: rotate(0);
      padding-top: 3px;
    }
  }
}

.main-footer {
  font-weight: 300;
  font-size: 0.875em;
  margin-top: auto;
  position: relative;
  z-index: 10;
  .copyright {
    padding: 6px $size-2;
    border-top: 1px solid rgba(white,.2);
    margin: 0 0 (-$size-2);
    text-align: center;
    p{
      margin: 0;
      opacity: .5;
    }
    a{
      font-size: 12px;
      
    }
  }
  .lead{
    text-transform: uppercase;
    color: rgba(255,255,255, .8);
    margin-top: 1.3rem;
  }
  .list-unstyled{

    li a{
      display: block;
      padding: 0.27rem 0;
    }
  }
  p{
    color: rgba(255,255,255,.6);
  }
  a:not(.btn){
    color: rgba(255,255,255,.6);
    text-decoration: none;
    &:hover{
      color: rgba(255,255,255,1);
    }
  }
  
}

.social-menu{
  padding:0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  li {
    padding: $size-1;
   
    svg{
      height: 24px;
      fill: currentColor;
    }
  }
}

// TrustArc cookie consent
#teconsent{
  @extend .container;
    position: relative;
    z-index: 12;
    height: 0;
    margin: 0;
    > a{
      height: 0;
      position: absolute;
      top: -5px;
      display: inline-block;
      font-size: 12px;
      color: rgba(255,255,255,.6);
      &:hover{
        color: rgba(255,255,255,1);
      }
    }
}


.illustration-wrapper{
  margin-top: 0;
  svg{
    max-width: 100%;
    display: block;
    margin: 0 auto;
    .main-color{
      fill:white;
    }
  }
}


.page-header{
  padding-top: $size-9 + $size-9 + $size-4;
  h1{
    font-weight: bold;
  }
  p{
    font-size: 1.2em;
    font-weight: 300;
    &:last-child{
      margin-bottom: 0;
    }
  }
}


.news-single{
  .page-header{
    padding-top: 5rem;
  }
  h1{
    font-size: 2rem;
  }
  .news-content p:first-child{
    font-size: 1.2em;
    font-weight: 300;
  }
}


.sla-single{
  .page-header{
    padding-top: 5rem;
  }
  h1{
    color: $gray-text;
    font-size: 2.7rem;
  }
 h2{
   font-size: 2rem;
   font-weight: bold;
   color: $blue-50;
 }
}

.single{
  .page-header{
    padding-top: 5rem;
  }
}

.single-case-study{
  margin-top: $size-8;
  .page-header{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: -100px;
    padding-top: 8rem;
    background-color: rgba(0,0,0,.3);
    background-blend-mode: multiply;
  }
}

.twitter-feed{
  max-height:700px;
}

.fit-v{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}


.home{
  background-image: radial-gradient(circle at 27% bottom, $blue-50 0%, $blue-20 60%), radial-gradient(circle at 70% 55%, $blue-50 0%, $blue-20 20%);
  margin-bottom: 0!important;
  //min-height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  padding-top: $size-9;
  
  .bottom{
    margin-top:auto;
  }

  .illustration-wrapper svg {
    max-width: 100%;
    height: 70%;
    display: block;
    margin: 2rem auto 0;
  }

}


.home-list{
 
    h3, h4{
      margin-bottom:0;
      margin-top: $size-2;
      font-weight: bold;
    }
    .bg-blue-20, .bg-blue-65, .blue-35{
      padding: $size-2;
      margin: 6px;
      border-radius: 1rem;
    }
    .bg-blue-35, .bg-blue-20{
      // height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -ms-flex-pack: distribute;
          justify-content: space-around;
      margin-right: 6px;
      width: calc(100% - 12px);
    }
    .border-white{
      margin: 12px 6px;
    }

    .home-illustration-wrapper{
      svg{
        max-width: 100%;
        max-height: 210px;
      }
    }
  
}

.mycloud-container{
  padding-top: $size-8;
  padding-bottom: $size-8;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
}


.section-separator{
  position: relative;
  margin: 0 -1rem;
  pointer-events: none;
  >.svg-wrap{
    position: absolute;
    z-index: 1;
  }
}

.updates-grid{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 -0.5rem;
  >.fit-v{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 30%;
            flex: 1 1 30%;
    padding: 0 1rem;
  }
}





@supports (display: grid) {
  .updates-grid{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 100%;
    margin: 0;
    padding: 0 3rem;
    >.fit-v{
      padding: 0;
      -webkit-box-flex: unset;
          -ms-flex: unset;
              flex: unset;
    }
    
    @media(min-width: $grid-breakpoint){
      grid-template-columns: 25% 25% 25% 25%;    
      .fit-v{
        grid-column: span 1;
        .card{
          margin-bottom: 0;
        }
        .card .card-image-square{
          padding-bottom: 100%;
        }
      }
      
      .fit-v:nth-child(5n + 1){
        grid-column: span 2;
        grid-row: span 1;
        .card .card-image-square{
          padding-bottom: 43.4%;
          background-position: center top;
        }
      }
    }
  }
}




.sla-page{
  padding-top: 6rem;
}

// fix bug
.youtube-wrapper iframe{
  margin-bottom: -6px;
}


.border-bottom{
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.05);
}