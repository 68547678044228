.panel{
  .collapse &, .collapsing &{
    padding: $size-2;
    -webkit-box-shadow: none;
            box-shadow: none;
    .small{
      color: $blue-27;
      font-size: 0.707rem;
      strong{
        color: $blue-primary;
      }
    }
  }
  background-color: white;
  padding: $size-3 $size-2;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.03), 0 20px 40px rgba(0,0,0,0.15);
          box-shadow: inset 0 -1px 0 rgba(0,0,0,.03), 0 20px 40px rgba(0,0,0,0.15);
  .small{
    font-size: 0.815rem;
    color: $blue-27;
  }

}

a.panel[data-toggle]{
  display: block;
  padding: 1rem 1rem;
  text-decoration: none;
  color: $gray-text;
  position: relative;
  background-color: $blue-98;
  position: relative;
  font-size: 0.815rem;
  &:hover{
    color: $blue-primary;
  }
  &:after{
    content: '+';
    position: absolute;
    right:1rem;
    top: 6px;
    font-size: 2em;
  }
  &[aria-expanded="true"]{
    background-color:$blue-89;
    color:$blue-35;
    font-weight: bold;
    &:after{
      content: '—';
    }
  }

}