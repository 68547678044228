// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;

$table-bg:                    transparent !default;
$table-accent-bg:             $gray-90 !default;
$table-hover-bg:              rgba(#000, .075) !default;
$table-active-bg:             $table-hover-bg !default;

$table-border-width:          $border-width !default;
$table-border-color:          $gray-90 !default;

$table-head-bg:               $gray-95 !default;
$table-head-color:            $blue-primary !default;

$table-dark-bg:               $gray-30 !default;
$table-dark-accent-bg:        rgba(#fff, .05) !default;
$table-dark-hover-bg:         rgba(#fff, .075) !default;
$table-dark-border-color:     lighten($gray-30, 7.5%) !default;
$table-dark-color:            $body-bg !default;

$table-striped-order:         odd !default;

$table-caption-color:         $text-muted !default;
