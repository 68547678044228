// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.less.
@mixin responsive-visibility(){
  display: block !important;
  table &  { display: table; }
  tr &     { display: table-row !important; }
  th &,
  td &     { display: table-cell !important; }
}

@mixin responsive-invisibility(){
  display: none !important;
}
