.sci-search-dropdown{
  position: relative;
  .sci-search-results-dropdown{    
    top: 100%;
    z-index: $zindex-dropdown;
  }
  .search-results{
    display: block;
    margin: 0 0 0 0;
    padding: 0;
    list-style: none;
    left: 0;
    z-index: 1000;
    min-width: 10rem;
    margin: .125rem 0 0;
    font-size: .875rem;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    //border: 1px solid #E5E5E5;
    border-radius: 0;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.175);
            box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.175);

    li a{
      cursor: pointer;
    }
  }
}


.input-group-search{
  position: relative;
  .form-control{
    padding-right: 2rem;
  }
  .icon-button{
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .form-control-sm + .icon-button{
    top: 5px;
  }
}


.signup-form{
  .form-group{
    margin-bottom: 24px;

    .form-control{
      padding-right: 1.8rem;
    }
    .feedback{
      position: relative;
      .form-text{
        position: absolute;
      }
    }
  }
}