.sci-nav-tabs-bar{
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  border-bottom: 1px solid $gray-80;
  li > a{
    display: block;
    color: $gray-text;
    text-decoration: none;
    padding: $size-1 $size-2;
    border-bottom: 4px solid transparent;
    &:hover, &:focus{
      color: $blue-primary;
    }
    &.nav-menu-active{
      color: $blue-primary;
      border-bottom-color: $blue-primary;
    }
  }
}