/*!
 * Containers
 */

// @include bg-color("gray-95", $gray-95);
// @include bg-color("blue-14", $blue-14);
// @include bg-color("blue-20", $blue-20);
// @include bg-color("blue-14", $blue-14);
// @include bg-color("primary", $blue-primary);
// @include bg-color("blue-65", $blue-65);
// @include bg-color("blue-35", $blue-35);
// @include bg-color("white", #fff!important);

@include bg-color;

@include text-color;

.text-muted{
  color: $gray-40;
}
