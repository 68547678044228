.svg-wrap{
  position: relative;
  width: 100%;
  > svg{
    position: absolute;
    width: 100%;
    fill: white;
  }
  &.bottom{
    bottom: 0;
    left: 0;
    right:0;
  }
  &.top{
    top: 0;
    left: 0;
    right:0;
  }
}
