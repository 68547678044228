@import "bootstrap/functions";
@import "vars";
@import "mixins";
@import "normalize";
@import "typography";
@import "dlt-icons";
@import "sag-icons";
@import "hamburger/hamburgers";
@import "navbar";
@import "masthead";
@import "layout";
@import "gsearch";
@import "bootstrap";
@import "containers";
@import "buttons";
@import "dropdown";
@import "transitions";
@import "blobs";
@import "alerts";
@import "tooltip";
@import "product-intro";
@import "product-form-cta";
@import "product-highlights";
@import "product-how-it-works";
@import "product-testimonials";
@import "product-case-studies";
@import "product-capabilities";
@import "products-overview";
@import "product-pricing";
@import "product-devcenter";
@import "product-news";
@import "resources";
@import "pagination";
@import "card";
@import "tabs";
@import "pills";
@import "tables";
@import "faqs";
@import "panel";
@import "user-info";
@import "list-group";
@import "marketo-forms";
@import "client-logo";
@import "popover";
@import "landing-page";
@import "landing-page-card-list";
@import "documentation";


// sci styles

@import "sci-page-header";
@import "sci-tabs";
@import "sci-forms";
@import "sci-list-group";
@import "sci-toolbar";
@import "sci-user-detail";
@import "profile-picture";
@import "sci-product-btns";
@import "sci-appswitcher";
@import "sci-prompt";

// import in last place
@import "utilities";
@import "aos";
@import "cookieconsent";

.hidden {
    @include responsive-invisibility();
}

.img-circle.top-level {
    background-color: white;
}
