




.nav-pills{
  margin: 0;
  padding: $size-2 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  .nav-item{
    padding: 0 .5rem
  }
  @media(max-width: $grid-breakpoint){
    padding: $size-1 0;
    font-size: .707rem;
    .nav-item{
      padding: 0.25rem;
    }
  }
  .nav-link{
    background-color: white;
    color: $blue-primary;
    border: 1px solid transparent;
    border-color: currentColor;
    text-decoration: none;
    border-radius: 1.2rem;
    padding: .344rem 1.2rem;
    display: inline-block;
    -webkit-transition: all .95s ease;
    transition: all .95s ease;
    &:hover{
      background-color: $blue-primary;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,.1)), to(rgba(0,0,0,.1)));
      background-image: linear-gradient(rgba(0,0,0,.1) 0%, rgba(0,0,0,.1) 100%);
      color: white;
      text-decoration: none;
    }
    .headroom--not-top &{
      background-color: transparent;
      color: white;
    }
  }
  .nav-link.active,
  .show > .nav-link {
    color: white;
    background-color: $blue-20;
  }
}