//AVENIR
@font-face {
  font-family: "Avenir";
  src: url('fonts/avenir-next-bold.woff2') format('woffs');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url('fonts/avenir-next-light.woff2') format('woffs');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url('fonts/avenir-next-regular.woff2') format('woffs');
  font-weight: 400;
  font-style: normal;
}
// ROBOTO
@font-face {
  font-family: "Roboto";
  src: url('fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url('fonts/Roboto-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url('fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url('fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url('fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}





$font-headings: "Avenir", "Roboto","Helvetica",Arial,sans-serif;
$font-base: "Roboto","Helvetica",Arial,sans-serif;
$font-family-base: $font-base;