body {

  .gsc-search-box.gsc-search-box-tools {
    width: 220px;
    padding: 16px 12px 0;
    position: relative;
  }

  .gsc-search-box-tools .gsc-search-box .gsc-input {
    background-color: transparent !important; //border: 2px solid @input-border;
    padding-right: 0;
    background-image: none !important;
  }

  .gsc-search-box-tools .gsc-search-box input.gsc-input {
    display: block !important;
    padding: 0 8px !important;
    width: 100% !important;
    height: 32px !important;
    border: 0 solid #e5e7e7 !important;
    border-radius: 0 !important;
    background-color: $blue-35 !important;
    background-image: none !important;
    color: white !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s !important;
    transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s !important;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s !important;
    text-indent: 0 !important;
    margin: 0 !important;

    &:focus {
      //border-color: @input-border-focus !important;
      background-color: white!important;
      color: $gray-text!important;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, .5) !important;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(255, 255, 255, .5) !important;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(255, 255, 255, .5) !important;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(255, 255, 255, .5) !important;
    }
  }

  .gsib_a {
    width: 100%;
    padding: 0 !important;
    border: 0 !important;
  }

  .gsst_b {
    padding-top: 3px;
  }

  .gsc-input-box-focus,
  .gsc-input-box-hover {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }


  td.gsc-search-button {
    position: relative;


    .gsc-search-button-v2,
    .gsc-search-button-v2:focus {
      width: 34px;
      padding: 0;
      height: 32px;
      border-radius: 0;
      background-color: $blue-35;
      border: 0;
      margin: 0;
      cursor: pointer;

      &:focus {
        border: 0;
        -webkit-box-shadow: none;
                box-shadow: none;
      }

      >svg {
        width: auto;
        height: auto;
        margin: auto;
        opacity: .8;
      }
    }

    .gsc-search-button-v2:hover {
      background-color: darken($blue-primary, 10%);

      >svg {
        opacity: 1;
      }
    }
  }

  .gsc-adBlock {
    display: none;
  }

  .gsc-input-box {
    border: 0;
    background-color: transparent;
    height: 32px;
  }

  table.gsc-search-box .gsst_b {
    position: absolute;
    top: 16px;
    right: 46px;

    .gsst_a {
      padding-top: 3.5px;
    }

    .gsst_a .gscb_a {
      color: $gray-70;
      font-size: 21px;
    }
  }
}

.gsc-control-cse .gsc-table-result {
  font-family: $font-base;
}

.cse .gsc-control-cse,
.gsc-control-cse {
  padding: 0 !important;
  border: 0 !important;
}