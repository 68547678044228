.devcenter-list{
  .col-md-6{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .card{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column nowrap;
              flex-flow: column nowrap;
      p:first-child{
        margin: 0;
      }
      h2{
         margin: 0;
         color: $gray-70;
         font-weight: 300;
        }
      .card-footer{
        margin-top: auto;
      }
      img{
        max-width: 100%;
        height: auto;
        max-height: 70px;
        margin: 0;
        display: block;
      }
    }
  }
}