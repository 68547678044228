.faqs{
  width: 100%;
  .product-cta &{
    margin-top: -120px;
  }
}

.faq{
  border: 1px solid $gray-90;
  border-bottom-width:0;
  background-color: white;
  -webkit-box-shadow: $picture-shadow;
          box-shadow: $picture-shadow;
  &:last-child{
    border-bottom-width:1px;
  }
  >a{
    display: block;
    padding: 1rem 1rem;
    text-decoration: none;
    color: $blue-35;
    position: relative;
    &:hover{
      color: $blue-primary;
    }
    &:after{
      content: '+';
      position: absolute;
      right:1rem;
      top: 6px;
      font-size: 2em;
    }
    &[aria-expanded="true"]{
      color:$blue-primary;
      font-weight: bold;
      &:after{
        content: '—';
      }
    }

  }
  .collapse-inner{
    padding: 0 1rem 1rem;
    color: $gray-text;
  }
}