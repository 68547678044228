.sci-prompt{
  padding: 2.5rem;
  .modal-header{
    font-size: 20px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 0 0 .5rem 0;
    border: 0;
    &:before{
      display: inline-block;
      font-family: "dlt-icons";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
      font-size: 1.5rem;
      position: relative;
      left:0;
      top:0;
      margin-right: .5rem;
    }
    
  }

  &.error-prompt, &.danger-prompt{
    border-left: 4px solid $error-color;
    .modal-header:before{
      content: $font-dlt-icons-dlt-icon-error1;
      color: $error-color;
    }
  }
  &.success-prompt{
    border-left: 4px solid $success-color;
    .modal-header::before{
      content: $font-dlt-icons-dlt-icon-success1;
      color: $success-color;
    }
  }
  &.info-prompt{
    border-left: 4px solid $info-color;
    .modal-header::before{
      content: $font-dlt-icons-dlt-icon-info1;
      color: $info-color;
    }
  }
  &.warning-prompt{
    border-left: 4px solid $warning-color;
    .modal-header::before{
      content: $font-dlt-icons-dlt-icon-warning1;
      color: $warning-color;
    }
  }

  .modal-body{
    padding: 0;
    font-size: $font-size-sm;
    p:last-child{
      margin-bottom:0;
    }
  }

  .modal-footer{
    border: 0;
    padding: 2.5rem 0 0 0;
  }
}