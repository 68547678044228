
.product-highlights{
  margin-top: -240px;
}

.highlights{
  flex-direction: column;
  h3{
    color: $blue-35;
  }
  @media(max-width: 991px){
    .row{
      .col-lg-5{
        -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
                order: 10;
        margin-top: $size-8;
      }
      .col-lg-6, .col-lg-7{
        -webkit-box-ordinal-group:3;
            -ms-flex-order:2;
                order:2;
      }
    }
 }
  @media(min-width: 992px){
    .row{
      .col-lg-5{
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .row:nth-child(even){
      .col-lg-5{
        margin-left: auto;
        //order: 2;
      }
    }
    .row:nth-child(odd){
      .col-lg-5{
        -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
                order: 10;
      }
      .col-lg-6, .col-lg-7{
        text-align: right;
      }
    }
    .row:last-child{
      .col-md-12 .separator{
        opacity: 0;
      }
    }
  }
  @media(min-width: 1200px){
    .row:nth-child(odd){
      .col-lg-5{
       margin-left: 40px; 
      }
    }
    .row:nth-child(odd){
      .col-lg-5{
        margin-left: 40px; 
      }
    }
  }
}