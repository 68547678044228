.alert{
  background-color: white;
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,.16);
          box-shadow: 0 2px 8px rgba(0,0,0,.16);
  font-size: $font-size-sm;
  padding: 1rem 2.5rem 1rem 3rem;
  position: relative;
  pointer-events: auto;

  &:before{
    display: inline-block;
    font-family: "dlt-icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    margin-right: .25rem;
    font-size: 1.5rem;
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
  }

  .close{
    position: absolute;
    z-index: 10;
    right: 1rem;
    top: 1rem;
    text-decoration: none;
    color: $gray-50;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    font-size: 1rem;
  }

  &.alert-error, &.alert-danger{
    border-left: 4px solid $error-color;
    &:before{
      content: $font-dlt-icons-dlt-icon-error1;
      color: $error-color;
    }
  }
  &.alert-success{
    border-left: 4px solid $success-color;
    &:before{
      content: $font-dlt-icons-dlt-icon-success1;
      color: $success-color;
    }
  }
  &.alert-info{
    border-left: 4px solid $info-color;
    &:before{
      content: $font-dlt-icons-dlt-icon-info1;
      color: $info-color;
    }
  }
  &.alert-warning{
    border-left: 4px solid $warning-color;
    &:before{
      content: $font-dlt-icons-dlt-icon-warning1;
      color: $warning-color;
    }
  }
}

// alerts wrapper
.alerts{
  position: fixed;
  z-index: 1050;
  max-height: 100vh;
  overflow-y: auto;
  padding: 1rem;
  margin: 0 auto;
  width: 100%;
  left: 0;
  pointer-events: none;
  .btn , .close{
    pointer-events: auto;
    cursor: pointer;
  }
  >.top{
    max-width: 1280px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  >.right{
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    >.alert{
      width: 280px;
    }
  }
  
}

