.product-testimonials{
  padding-top: $size-9;
}


.testimonial-item{
  margin-bottom: $size-6;
  .img-circle{
    border: 1px solid white;
    -webkit-box-shadow: $testimonial-shadow;
            box-shadow: $testimonial-shadow;
  }
}

.testimonials_slider{
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 300px;
  .slider{
    width: 100%;
    height: 100%;
  }
  .slide{
    padding-top: 0;
    min-width: 100vw;
    img{
      max-width: 160px!important;
    }
  }
}

.slider-buttons{
  display: flex;
  width: 100%;
  justify-content: center;
  > div{
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: $gray-90;
      margin: 0 6px 0 0;
      opacity: 1;
      &.selected{
        background: $blue-primary;
      }
  }
}



// .testimonials-wrapper{
//   position: relative;
//   padding: 0;
//   &:before{
//     content:'';
//     position: absolute;
//     height: 100px;
//     top: 0;
//     right: 0;
//     left: 0;
//     //background: red;
//     background-image: linear-gradient(180deg , rgba(255,255,255,1) 10%,rgba(255,255,255,0) 100%);
//     z-index: 10;
//   }
//   &:after{
//     content:'';
//     position: absolute;
//     height: 100px;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     //background: red;
//     background-image: linear-gradient(0 , rgba(255,255,255,1) 10%,rgba(255,255,255,0) 100%);
//     z-index: 10;
//   }

//   .testimonials-slider{
//     max-height: 40vh;
//     padding: 100px $size-2;
//     overflow-y: auto;
//   }
  
// }
