.sci-product-btns{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: $size-2;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  > a{
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33%;
            flex: 0 0 33%;
    color: $almost-black;
    text-align: center;
    font-size: 2rem;
  }
  >.sci-product-main-btn{
    font-size: 4rem;
    margin-left: auto;
  }

}