$sizes: (size-1:$size-1,
size-2:$size-2,
size-3:$size-3,
size-4:$size-4,
size-5:$size-5,
size-6:$size-6,
size-7:$size-7,
size-8:$size-8,
size-9:$size-9,
size-10: $size-10,
size-11: $size-11,
size-12: $size-12,
size-13: $size-13,
size-14: $size-14,
size-15: $size-15);

@mixin pad($direction) {

  @each $name,
  $size in $sizes {
    .pad-#{$direction}-#{$name} {
      padding-#{$direction}: map-get($sizes, $name);
    }
  }
}
@mixin margin($direction) {

  @each $name,
  $size in $sizes {
    .margin-#{$direction}-#{$name} {
      margin-#{$direction}: map-get($sizes, $name);
    }
  }
}