.top-products-overview{
  margin: 0 -16px;
  position: relative;
  background-image: radial-gradient(circle at center -38%,  #3dd8ff 0%, #101750  64%);
  
  .center-products{
    min-height: 25vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    color: white;
    padding-top: 8vh;
    margin-bottom: -5rem;
  }
}
.scene--full {
  position: absolute;  
}

.product-list .col-lg-6 > svg{
  max-width: 100%;
  height: auto;
}