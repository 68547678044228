.client-logo{
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: inline-block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0px 6px 20px rgba(0,0,0,0.06);
  background-color: white;

  &.large-logo{
    box-shadow: none;
    width: 100%;
    max-width: 240px;
    height: 210px;
    border-radius: 0;
  }
  &.full-logo{
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: 90%;
  }
}