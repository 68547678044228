.resources-page, .home, .sla-page{
  background-color: $blue-20;
  background-image: radial-gradient(circle at 27% bottom, $blue-50 0%, $blue-20 60%);
  margin-bottom: -165px;
  color: white;
}

.resources-list{
  .collapse.show{
    overflow: visible;
  }
}
