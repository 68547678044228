
.product-how-it-works{
  background-color: $blue-20;
  background-image: radial-gradient(circle at 12% -10%, $blue-50 0%, $blue-20 49%);
  //background-blend-mode: multiply;
  position: relative;

  .svg-wrap.top{
    margin-bottom: -120px;
  }
  
}