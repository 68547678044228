.sci-appswitcher{
  width: 275px;
  padding: 0;
  &.dropdown-menu{
    left: 16px!important;
  }
  @media(min-width: $grid-breakpoint){
    width: 328px;
    max-width: none;
    &.dropdown-menu{
      left: auto!important;
      right: -50px;
      &.arrow-top{
        &:before{
          left: auto;
          right: 62px;
        }
        &:after{
          left: auto;
          right: 63px;
        }
      }
    }
  }

  .sci-appswitcher-inner{
    overflow-y: auto;
    max-height: calc(100vh - 280px);
    @media(min-width: $grid-breakpoint){
      max-height: calc(100vh - 100px);
    }
    &::-webkit-scrollbar {
      width: $size-1;/* for vertical scrollbars */
      height: $size-1;/* for horizontal scrollbars */
    }
    &::-webkit-scrollbar-track {
      background: $gray-90;
      border-radius: $size-1;
    }
    &::-webkit-scrollbar-thumb {
      background: $gray-60;
      border-radius: $size-1;
    }
  }

  .switcher-apps{
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    padding: 1rem 1.5rem 1.5rem;
    >a{
      padding: .5rem .25rem;
      width: 33.333333%;
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      color: $gray-text;
      border: 2px solid transparent;
      font-size: 12px;
      margin-top: .5rem;
      // font-weight: 600;
      &:hover{
        text-decoration: none;
        border-color: $blue-primary;
      }
    }
    svg{
      display: block;
      margin: 0 auto .5rem;
      
      width: 40px;
      height: 40px;
      @media(min-width: $grid-breakpoint){
        width: 48px;
        height: 48px;
      }
    }
    + .switcher-apps{
      margin-top: -1rem;
      position: relative;
      &:before{
        content: '';
        display: block;
        border-top: 1px solid $gray-80;
        width: 100%;
        margin-bottom: 1rem; 
      }
    }
  }
}