.landing-page{


  .fullpage-image{
    margin-left: -1rem;
    margin-right: -1rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    min-height: 50vh;
    padding-top: 6rem;
    @media(min-width: $grid-breakpoint){

      &:first-of-type{
        padding-top: $masthead-height * 3;
      }
    }
    + .pad-top-size-8{
      padding-top: 0;
    }
  }

  .centered-article{
    p:first-of-type{
      @extend .lead;
    }
  }


  .product-cta{
    padding-bottom: 0;
    .container{
      h1{
        margin-bottom: 1rem;
      }
      p, ul, form{
        text-align: left;
      }
      > p:first-of-type{
        @extend .lead;
      }
    }
    &:last-of-type{
      padding-bottom: 4.5rem;
      .container + .svg-wrap{
        display: none;
      }
    }
  }

}


.even-odd-list{
  >.container{
    img{
      @extend .img-responsive;
    }
    >ul{
      padding: 0;
      margin: 0;
      list-style: none;
      @extend .row;

      >li:nth-child(odd){
        @extend .col-lg-7;
      }
      >li:nth-child(even){
        @extend .col-lg-5;
      }
    }
  }
}