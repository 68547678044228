//Size units
$base-size: .5rem; // 8px
$half-size: $base-size / 2;
$size-1: $base-size;
$size-2: $base-size * 2;
$size-3: $base-size * 3;
$size-4: $base-size * 4;
$size-5: $base-size * 5;
$size-6: $base-size * 6;
$size-7: $base-size * 7;
$size-8: $base-size * 8;
$size-9: $base-size * 9;
$size-10: $base-size * 10;
$size-11: $base-size * 11;
$size-12: $base-size * 12;
$size-13: $base-size * 13;
$size-14: $base-size * 14;
$size-15: $base-size * 15;

// Grid

$grid-breakpoint: 992px;


// masthead
$masthead-height: $size-8;
$masthead-nav-width: $base-size * 30;


// MY CLOUD
$spacers: (
  sci-4: 4px,
  sci-8: 8px,
  sci-12: 12px,
  sci-16: 16px,
  sci-24: 24px,
  sci-40: 40px
);  