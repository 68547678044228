

.product-case-studies{
  background-color: $blue-20;
  background-image: radial-gradient(circle at 37% , $blue-50 0%, $blue-20 60%);
  //background-blend-mode: multiply;
  position: relative;
}



