.sci-toolbar{
  margin-top: $size-2;
  margin-bottom: $size-2;
  .btn-link{    
    color: $link-color;
    padding-top: 0;
    padding-bottom:0; 
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;    
    line-height: $size-4;
    height: $size-4;
    border: 1px solid $blue-primary;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    .icon{
      font-size: 1.4rem;
      margin-right: 4px;
    }
    &:hover{
      outline: none;
      box-shadow: inset 0 0 0 1px $blue-primary;
    }
    &:active{
      background-color: $blue-89;
    }
  }
}