.sci-list-group-header{
  font-size: $font-size-sm;
  font-weight: bold;
  letter-spacing: 0.03em;
  padding: $size-2 $size-1;
  background-color: $gray-95;
}

.sci-list-group{
  font-size: $font-size-sm;
  margin-bottom: $size-2;
  @media screen and (max-width: ($grid-breakpoint - 1)) {
    border-top: 1px solid $gray-90;
  }
  .sci-list-group-item{
    padding: $size-2 $size-1;
    border-bottom: 1px solid $gray-90;
    &:hover{
      background-color: transparent;
      border: 2px solid $blue-primary;
      margin: -2px -2px -1px;
    }
    p{
      margin-bottom: 0;
    }
    .btn{
      padding-top: 0; 
      padding-bottom: 0; 
    }
    .btn-link.btn-lg{    
      padding: 4px 4px 4px 8px;
      margin-right: 12px;
      margin-left: $size-1;
      &:hover{
        box-shadow: inset 0 0 0 2px $blue-primary;
      }
      &:active{
        background-color: $blue-89;
      }
    }
   
  }
}


.user-avatar{
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23e5e5e5'%3E%3Cpath d='M12,0 C5.37248,0 0,5.37248 0,12 C0,18.62752 5.37248,24 12,24 C18.62752,24 24,18.62752 24,12 C24,5.37248 18.62752,0 12,0 M12,2.33008 C17.34416,2.33008 21.67008,6.65472 21.67008,12 C21.67008,17.344 17.34512,21.66992 12,21.66992 C6.65584,21.66992 2.32992,17.34512 2.32992,12 C2.32992,6.656 6.65504,2.33008 12,2.33008' id='Fill-10-Copy'%3E%3C/path%3E%3Cpath d='M17.8030737,17.059871 C16.3925417,18.6765462 14.3166414,19.6973926 12,19.6973926 C9.68299989,19.6973926 7.60644858,18.6761276 6.19576985,17.0586097 C6.24672632,17.0453999 6.29906049,17.0325848 6.3528094,17.02016 C8.50850904,16.52224 10.6334861,16.07808 9.61068089,14.19264 C6.5819485,8.608 8.74788899,5.44 11.9993599,5.44 C15.1881057,5.44 17.4090908,8.49024 14.388039,14.19392 C13.3959563,16.06784 15.4454071,16.51328 17.6459105,17.02144 C17.6997021,17.033858 17.7520775,17.0466669 17.8030737,17.059871 Z' id='Combined-Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  margin-right: $size-1;
}

.user-new {
  width: 40px;
  height: 40px;

}
.userImg{
  position: relative;
  max-width: 40px;
  &:after{
    position: absolute;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23e5e5e5'%3E%3Cpath d='M12,0 C5.37248,0 0,5.37248 0,12 C0,18.62752 5.37248,24 12,24 C18.62752,24 24,18.62752 24,12 C24,5.37248 18.62752,0 12,0 M12,2.33008 C17.34416,2.33008 21.67008,6.65472 21.67008,12 C21.67008,17.344 17.34512,21.66992 12,21.66992 C6.65584,21.66992 2.32992,17.34512 2.32992,12 C2.32992,6.656 6.65504,2.33008 12,2.33008' id='Fill-10-Copy'%3E%3C/path%3E%3Cpath d='M17.8030737,17.059871 C16.3925417,18.6765462 14.3166414,19.6973926 12,19.6973926 C9.68299989,19.6973926 7.60644858,18.6761276 6.19576985,17.0586097 C6.24672632,17.0453999 6.29906049,17.0325848 6.3528094,17.02016 C8.50850904,16.52224 10.6334861,16.07808 9.61068089,14.19264 C6.5819485,8.608 8.74788899,5.44 11.9993599,5.44 C15.1881057,5.44 17.4090908,8.49024 14.388039,14.19392 C13.3959563,16.06784 15.4454071,16.51328 17.6459105,17.02144 C17.6997021,17.033858 17.7520775,17.0466669 17.8030737,17.059871 Z' id='Combined-Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}