.product-capabilities, .dev-center{
  background-color: $blue-20;
  background-image: radial-gradient(circle at 27% bottom, $blue-50 0%, $blue-20 60%);
  margin-bottom: -165px;
  color: white;
}


.dev-center {
  margin-bottom:0;
  > .svg-wrap{
    height: 81px!important;
    svg{
      height: 100%;
    }
  }
}

.product-cta{
  color: white;
  padding-top: 0;
  padding-bottom: $size-9;
  background-color: $blue-20;
  background-image: radial-gradient(circle at right bottom, $blue-50 0%, $blue-20 60%);
}