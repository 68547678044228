.sci-user-detail{ 
  margin-bottom: $size-2;
  h2{
    font-size: 24px;
    padding-top: 1rem;
    font-family: $font-base;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $font-weight-normal;
  }
  
}

.sci-view-user-detail{
  .form-group{
    margin-bottom: $size-1;
  }
}