.landing-page-card-list{
  .container {
    text-align: center;
    
    > ul{
      list-style: none;
      padding: 0;
      margin: 0;
      @extend .row;
      @extend .justify-content-center;
      text-align: left;
      >li{
        @extend .col-lg;
        @extend .fit-v;

        >.card {
          >strong{
            @extend .card-title;

          }
          ul{
            list-style: disc;
            padding-left: 1rem;
            padding-top: 1rem;
            @extend .text-small;
          }
        }
      }
    }
  }
  &:last-of-type{
    .container + .svg-wrap{
      display: none;
    }
  }
}