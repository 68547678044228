/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
  @-ms-viewport {
    width: device-width;
  }

  // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}



@import "mixins/breakpoints";
@import "mixins/grid-framework";
@import "mixins/grid";
@import "mixins/visibility";

@import "bootstrap/grid";
@import "bootstrap/utilities/display";
@import "bootstrap/utilities/flex";
@import "bootstrap/utilities/spacing";
@import "bootstrap/utilities/visibility";

@import "bootstrap/forms";
@import "bootstrap/custom-forms";
@import "bootstrap/input-group";
@import "bootstrap/buttons";
@import "bootstrap/button-group";
@import "bootstrap/modal";