// Settings
// ==================================================
$hamburger-padding-x : 15px !default;
$hamburger-padding-y : 15px !default;
$hamburger-layer-width : 40px !default;
$hamburger-layer-height : 4px !default;
$hamburger-layer-spacing : 6px !default;
$hamburger-layer-color : currentColor !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity : 0.7 !default;
$hamburger-hover-transition-duration : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;