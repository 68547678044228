// Tooltips

$tooltip-font-size:                 0.775rem !default;
$tooltip-max-width:                 300px !default;
$tooltip-color:                     $gray-text !default;
$tooltip-bg:                        white !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   1 !default;
$tooltip-padding-y:                 .25rem !default;
$tooltip-padding-x:                 .5rem !default;
$tooltip-margin:                    0 !default;

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;

