


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  width: 100%;
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

.tabs-menu-container{
  position: relative;
  padding-top: 12rem;
}

.tabs-menu{
  position: absolute;
  top:0;
  left:0;
  right:0;
  z-index: 1028;
  &:before{
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    left:50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: $blue-35;
    -webkit-transition: all .95s ease;
    transition: all .95s ease;
    border-radius: 50px;
  }
  > .container{
    position: relative;
    z-index: 10;
  }
  //transition: all .35s ease;
  &.headroom--not-top {
    position: fixed;
    top: 112px;
    &.nomenu{
      top: 48px;
    }
    &:before{
      width: 100%;
      height: 100%;
      border-radius:0;
    }

    &.headroom--unpinned {
      @media(min-width: $grid-breakpoint){
        -webkit-transform: translateY(-$masthead-height);
                transform: translateY(-$masthead-height);
      }
      &.nomenu{

        @media(min-width: $grid-breakpoint){
           -webkit-transform: translateY(-48px);
           transform: translateY(-48px);
        }
      }
    }
  }

}
