$grid-columns: 12;
$grid-gutter-width: 32px;
$enable-grid-classes: true !default;
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;

$font-size-base:              1rem !default; // 16px
$font-size-base-sm:           .875rem !default; // 14px
$font-size-base-xs:           .75rem !default; // 12px

$body-bg:                   white !default;
$body-color:                $gray-text !default;

// caret
$caret-width:                 .3em !default;

$border-width:                1px !default;
$border-color:                $gray-30 !default;
$border-radius:               0 !default;
$border-radius-lg:            0 !default;
$border-radius-sm:            0 !default;

$nav-divider-color:                 $gray-30 !default;
$nav-divider-margin-y:              .25rem !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                10rem !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-bg:                       white !default;
$dropdown-border-color:             white !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             0 !default;
$dropdown-divider-bg:               $gray-90 !default;
$dropdown-box-shadow:               0 1px 4px rgba($black, .5) !default;

$navbar-box-shadow:                 0 0.02rem 0.1rem rgba($black, .15) !default;

$dropdown-link-color:               $gray-text !default;
$dropdown-link-hover-color:         $gray-text!default;
$dropdown-link-hover-bg:            $gray-90 !default;

$dropdown-link-active-color:        $gray-text !default;
$dropdown-link-active-bg:           $gray-90 !default;

$dropdown-link-disabled-color:      $gray-60 !default;

$dropdown-item-padding-y:           .375rem !default;
$dropdown-item-padding-x:           1rem !default;

$dropdown-header-color:             $gray-60 !default;



// Z-INDEX
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0,
1: ($spacer * .25),
2: ($spacer * .5),
3: $spacer,
4: ($spacer * 1.5),
5: ($spacer * 3)),
$spacers);



$grid-breakpoints: ( // Extra small screen / phone
xs: 0,
// Small screen / phone
sm: 576px,
// Medium screen / tablet
md: 768px,
// Large screen / desktop
lg: 992px,
// Extra large screen / wide desktop
xl: 1200px,

xxl: 1400px);

$container-max-widths: (sm: 540px,
md: 720px,
lg: 960px,
xl: 1140px,
xxl: 1280px);