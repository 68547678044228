.product-cta-form-container {
  position: relative;
}

.product-cta-success-message {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 1.5rem;
  background-color: white;
  color: $gray-text;

  h3 {
    color: $blue-35;
  }

}