.user-info{

  .dropdown-toggle{
    display: block;
    margin-top: -6px;
    padding: 0!important;
    &::after{
      content: '';
    }
  }
  .img-circle{
    max-width: 40px!important;
    max-height: 40px!important;
  }
  .user-details{
    font-size: 1rem;
    font-weight: bold;
  }
  
}