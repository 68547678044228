$popover-font-size:                 $font-size-sm !default;
$popover-bg:                        white !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              rgba($black, .05) !default;
$popover-border-radius:             .25rem !default;
$popover-box-shadow:                0 .25rem .5rem rgba($black, .2) !default;

$popover-header-bg:                 darken($popover-bg, 3%) !default;
$popover-header-color:              $gray-text !default;
$popover-header-padding-y:          1rem !default;
$popover-header-padding-x:          1rem !default;

$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $popover-header-padding-y !default;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;